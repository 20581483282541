.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.flexrowclose {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logoS {
  width: 125px;
  height: 95px;
  margin: 10px;
}

.SNAPS {
  color: aliceblue;
  font-size: 22px;
  font-weight: 10;
  width: 90%;
  text-align: center;
}

h1 {
  font-weight: 250;
  text-align: center;
}
h2 {
  color: aliceblue;
  font-weight: 250;
  text-align: center;
}
h3 {
  font-weight: 250;
  color: aliceblue;
  text-align: center;
}
h4 {
  font-weight: 250;
  text-align: center;
}
h5 {
  font-weight: 250;
  text-align: center;
}
p {
  font-weight: 350;
  text-align: center;
  color: aliceblue;
}

.flexcolumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 5px;
}

.image {
  width: 80vw;
  border-radius: 8px;
}

.imagewide {
  width: 90vw;
  border-radius: 15px;
}

.imagemedium {
  width: 90vw;
  border-radius: 15px;
}

.imagelarge {
  width: 90vw;
  border-radius: 15px;
}

.flexcolumnDR {
  display: flex;
  flex-direction: row; /* Default to column on mobile */
  justify-content: space-around;
  align-items: center;
  gap: 2vw;
}
.flexcolumnDRO {
  display: flex;
  flex-direction: column; /* Default to column on mobile */
  justify-content: space-around;
  align-items: center;
}
.paddingXL{
  padding-left: 6vw;
  padding-right: 6vw;
}
.flexcolumnDRA {
  display: flex;
  flex-direction: column; /* Default to column on mobile */
  justify-content: space-around;
  align-items: center;
}
@media (min-width: 640px) { /* This is Tailwind's 'sm' breakpoint */
  .image{
    width: 15vw;
    border-radius: 15px;
  }
  .imagewide {
    width: 40vw;
    border-radius: 15px;
  }
  .imagemedium {
    width: 25vw;
    border-radius: 15px;
  }
  .imagelarge {
    width: 60vw;
    border-radius: 15px;
  }
  .SNAPS {
    color: aliceblue;
    font-size: 35px;
    font-weight: 10;
    width: 90%;
  }
  .sidemargin {
    margin-left: 0.2vw;
    margin-right: 0.2vw;
  }
  .flexcolumnDRA {
    flex-direction: row; /* Switch to row for screens larger than 640px */
  }
}
@media (max-width: 640px) {
  .paddingXL{
    padding-left: 25vw;
    padding-right: 25vw;
  }
}
@media (min-width: 1100px) {
  .sidemargin {
    margin-left: 3vw;
    margin-right: 3vw;
  }
}
@media (min-width: 1750px) {
  .SNAPS {
    color: aliceblue;
    font-size: 100px;
    font-weight: 10;
    width: 90%;
  }
}

@media (min-width: 1500px) {
  .flexcolumnDR {
    flex-direction: row; /* Switch to row for screens larger than 640px */
  }
  .DEX {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.DEX {
  padding-left: 40px;
  padding-right: 40px;
}

.padding{
  padding: 3px;
}



.border {
  border: #b3ffff;
  border-style: solid;
  border-color: #b3ffff;
  border-radius: 15px;
  border-width: 1px;
}




#dexscreener-embed{
  position:relative;
  width:150%;
  padding-bottom:190%;
  padding-left: 10px;
  padding-right: 10px;
}
@media(max-width:640px){
  #dexscreener-embed{
    width: 150%;
    border-radius: 15px;
  }
}
#dexscreener-embed iframe{
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
  border:0;

}


.main {
  width: 100vw;
  min-height: 100vh;
  height: auto;
  padding: 4rem 0;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 1rem;
  margin: 0 auto;
}

.gradient-text-0 {
  background: -webkit-linear-gradient(#bfa3da, #84309c, #c735b0);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-1 {
  background: -webkit-linear-gradient(#a79af9, #7aa8d2);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-2 {
  background: -webkit-linear-gradient(#bfa3da, #743f93);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-3 {
  background: -webkit-linear-gradient(#c35ab1, #e9a8d9);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
  text-decoration-color: #84309c;
}

.title {
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: left;
}

.connect {
  margin-bottom: 2rem;
}

.description {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #555;
  border-radius: 5px;
  padding: 0.5em;
  font-size: 1.1rem;
}

.grid {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  background-color: #131313;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 440px;
  overflow: none;
  border: none;
}

.card-text {
  padding: 1rem;
}

.card img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 4px 4px 0 0;
  margin-bottom: 12px;
  border: none;
}

.card:hover,
.card:focus,
.card:active {
  background-color: #272c34;
  opacity: 80%;
}

.card h2 {
  margin: 0 0 0.75rem 0;
}

.card p {
  margin: 0;
  font-size: 1rem;
  line-height: 1.75;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 768px) {
  .header {
    margin-left: 0;
  }
  .grid {
    width: 100%;
    flex-direction: column;
  }
  .title {
    font-size: 3rem;
    line-height: 1.15;
  }

}
@media (min-width: 936px) {
  #dexscreener-embed{
    width: 170%;
    border-radius: 15px;
  }
}
@media (min-width: 700px) {
  #dexscreener-embed{
    width: 120%;
    border-radius: 15px;
  }
}
@media (min-width: 1500px) {
  #dexscreener-embed{
    width: 200%;
    border-radius: 15px;
    height: 200%;
    padding-bottom: 300%;
  }
}
.card,
.footer {
  border-color: #222;
}
.logo img {
  filter: invert(1);
}
